import request from '@/utils/requestV2'
const qs = require('qs')

// 查看签约商品交易情况
export function getOperatorTransactionPage (data) {
  return request({
    url: '/ooh-life/localLife/order/getOperatorTransactionPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 有赞商品类型枚举接口
export function getOrderItemTypeList (data) {
  return request({
    url: '/ooh-life/localLife/order/getOrderItemTypeList',
    method: 'post',
    data: qs.stringify(data)
  })
}
