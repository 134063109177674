<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4" v-if="isAuth('locallife_AllOrder')">
        <Select
          v-model="query.userId"
          size="small"
          placeholder="地推用户"
          clearable
        >
          <Option
            v-for="(channel, index) in channelCompanyList"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
// import { getCompanyChild } from '@/api/os/company'
import { isAuth } from '@/utils/permission'
import { getUserPage } from '@/api/os/user'

import { getOperatorTransactionPage } from '@/api/life/order'

export default {
  components: {
  },
  data () {
    return {
      channelCompanyList: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '商品名称',
          key: 'title'
        },
        { title: '数量', key: 'num' },
        { title: '商品金额', key: 'totalFee' },
        { title: '实付金额', key: 'totalAmount' },
        { title: '支付方式', key: 'payTypeName' },
        { title: '地推', key: 'userName' },
        { title: '状态', key: 'statusName' },
        { title: '创建时间', key: 'createTime' }
      ]

    }
  },
  created () {
    if (isAuth('locallife_AllOrder')) {
      this.initChannelList()
    }

    this.initPageData()
  },
  methods: {

    initChannelList () {
      getUserPage({ companyId: this.$store.getters.token.userInfo.companyId, pageNumber: 1, pageSize: 1000 }).then(res => {
        this.channelCompanyList = res.list
      })
    },
    initPageData () {
      if (isAuth('locallife_OwnOrder')) {
        this.query.userId = this.$store.getters.token.userInfo.userId
      }
      getOperatorTransactionPage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    }
  },
  watch: {
  },
  computed: {
  }
}
</script>
